<template>
    <div>
        <b-overlay :show="loading">
            <b-card>
                <b-row>
                    <b-col md="8">
                        <h4 class="card-title">Generar Certificado</h4>
                    </b-col>
                    <b-col md="4" class="text-right mb-2">
                        <b-button
                                variant="outline-primary"
                                @click="goList()"
                        >
                            <feather-icon
                                    icon="ArrowLeftIcon"
                                    class="mr-50"
                            />
                            Volver
                        </b-button>
                    </b-col>
                    <b-col  md="6">
                        <v-select
                                v-model="selectImg"
                                :options="itemsImg"
                                label="nombre"
                                placeholder="Seleccione Imagen"
                                @input="selectImage"
                        >
                            <template slot="option" slot-scope="option">
                                <b-avatar square :src="option.image"></b-avatar>
                                {{ option.nombre }}
                            </template>
                        </v-select>
                    </b-col>
                    <b-col md="6" >
                        <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                type="submit"
                                @click="addImg">
                            <feather-icon icon="ImageIcon" class="mr-50"/>
                            <span class="align-middle">Subir Nueva Imagen</span>
                        </b-button>
                    </b-col>
                    <b-col md="6" class="mt-2">
                        <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                    </b-col>
                    <b-col md="6" class="d-none">
                        <b-form-group
                        >
                            <h5 class="font-weight-bold">
                                Imagen de fondo del Certificado
                            </h5>
                            <div class="form-label-group">

                                <div class="d-flex mb-3">
                                    <b-form-file
                                            v-model="image"
                                            placeholder="Seleccione un archivo o suéltelo aquí..."
                                            drop-placeholder="Suelte el archivo aquí..."
                                            class="w-auto flex-grow-1"
                                            accept="image/*"
                                            browse-text="Seleccione"
                                    />
                                    <b-button v-if="hasImage" variant="danger" class="ml-3" @click="clearImage">Eliminar</b-button>
                                </div>
                                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded></b-img>

                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <div class="d-none">{{urlCertificado}}{{response}}{{urlImage}}</div>
                        <div class="text-danger mt-1 mb-1 font-weight-bold">* Puede editar el texto del certificado, sustituir o eliminar el texto que se encuentra entre paréntesis según sus necesidades.</div>
                        <h5 class="font-weight-bold">
                            Texto del Certificado
                        </h5>
                        <quill-editor
                                v-model="certificateDetail"
                                ref="editor"
                        />
                    </b-col>
                    <b-col md="12" class="text-right mt-2">
                        <b-button
                                variant="outline-primary"
                                class="mr-1"
                                @click="generarCertificadoDemo"
                        >
                            Generar Certificado
                        </b-button>
                    </b-col>

                </b-row>
            </b-card>
            <b-modal
                    id="modal-image"
                    ref="modal_img"
                    title="Imágenes"
                    ok-title="Cerrar"
                    size="lg"
                    ok-only
            >
                <imagenes></imagenes>
            </b-modal>
        </b-overlay>
    </div>
</template>

<script>  import Ripple from "vue-ripple-directive";
const base64Encode = data =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
import {
    BRow,
    BCol,
    BFormFile,
    BImg,
    BButton,
    BOverlay,
    VBTooltip,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import imagenes from "@/views/imagenes/Listar";
export default {
    name: "ExportarCertificado", components: {
        BRow,
        BCol,
        quillEditor,
        BFormFile,
        BImg,
        BButton,
        BOverlay,
        Quill,
        'imagenes':imagenes,

    },
    directives: {
        Ripple,
    },
    data: () => ({
        image: null,
        imageSrc: null,
        imageDelete: false,
        porDefecto: false,
        uploadedFiles: [],
        holding: null,
        empresa: null,
        otec: null,
        curso: null,
        byDefault: true,
        certificateDetail: `<div id="certificate_detail" style="text-align: center;">
                                <h2>La Gerencia de nuestra OTEC hace entrega del presente Diploma a:</h2>
                                <h3>(Escriba Nombre estudiante)</h3>
                                <h3>(Escriba frase certificado)</h3>
                                <h3<strong>(Escriba nombre Curso)</strong></h3>
                                <h3>Realizado en Santiago entre (Escriba fecha inicio) y el (Escriba fecha Termino)</h3>
                                <h3>con un total de (Escriba horas curso) horas</h3>
                                <h3>Código de validez  (Escriba código)</h3>
                            </div>`,
        url: null,
        urlPdf: null,
        selectImg: null,
        idImg: null,
    }),
    computed: {
        itemsImg() {
            return this.$store.state.imagenes.list;
        },
        hasImage() {
            return !!this.imageSrc;
        },
        editor() {
            return this.$refs.editor.quill
        },
        loading() {
            return this.$store.state.gestionarCertificados.loading;
        },
        response() {
            let response = this.$store.state.gestionarCertificados.response;
            if(response == 'success' ){
                this.$router.push({ name: "gestionar_certificados" });
                this.showToast('success','El certificado se ha creado correctamente!!')
                this.$store.commit('gestionarCertificados/setResponse', null)
                this.$store.commit('gestionarCertificados/setUrlImage', null)
            }
            return response
        },
        urlCertificado() {
            if(this.urlPdf !== this.$store.state.gestionarCertificados.url)
            {
                this.urlPdf = this.$store.state.gestionarCertificados.url
                window.open(this.urlPdf , '_blank') //to open in new tab
                this.$store.commit('gestionarCertificados/setUrl', null)
            }
            return this.urlPdf;
        },
        urlImage() {
            if(this.$store.state.gestionarCertificados.urlImage !== null && !this.imageDelete){
                this.imageSrc = this.$store.state.gestionarCertificados.urlImage
                var arrUrl = String(this.imageSrc).split('/');
                this.url = arrUrl[arrUrl.length - 1];
            }
            return this.imageSrc;
        },
    },
    watch: {
        image(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue) {
                    base64Encode(newValue)
                        .then(value => {
                            this.imageSrc = value;
                            let file = {'file': [this.image]}
                            this.$store.dispatch('gestionarCertificados/uploadFileImage', file)
                        })
                        .catch(() => {
                            this.imageSrc = null;
                        });
                } else {
                    this.imageSrc = null;
                }
            }
        }
    },
    mounted() {
        this.getListImagenes()
        this.imageSrc = null;
        this.$store.commit('gestionarCertificados/setUrlImage', null)
    },
    methods: {
        getListImagenes() {
            this.$store.dispatch('imagenes/getListFilter')
        },
        selectImage(img){
            if(img != null){
                this.idImg = img.id;
                this.imageSrc = img.image;
                if(this.imageSrc != null){
                    var arrUrl = String(this.imageSrc).split('/');
                    this.url = arrUrl[arrUrl.length - 1];
                }
            }else {
                this.imageSrc = null;
                this.selectImg = null;
                this.idImg = null;
                this.url = null;
            }

        },
        addImg(){
            this.$refs.modal_img.show();
        },
        showToast(variant, mensaje) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Notificación',
                    icon: 'BellIcon',
                    text: mensaje,
                    variant,
                },
            })
        },
        clearImage() {
            this.image = null
            this.imageSrc = null
            this.imageDelete = true
        },
        convertirCertificateDetail(){
            let cadena = String(this.certificateDetail)
            cadena = cadena.replace('<div id="certificate_detail" style="text-align: center;">', '')
            cadena = cadena.replace('</div>', '')
            cadena = btoa(unescape(encodeURIComponent(cadena)))

            return cadena
        },
        generarCertificadoDemo() {
            if (this.imageSrc == null){
                this.showToast('warning','Debe seleccionar una image de fondo para el Certificado')
                return
            }
            this.showToast('info','Se está generando el certificado. Por favor, espere...')
            let data_certificado = {

                'certificate': {
                    'url': this.url,
                    'certificate_detail': this.convertirCertificateDetail() ,
                }
            }

            this.$store.dispatch('gestionarCertificados/generarCertificadoAlumno',data_certificado)
        },
        goList(){
            this.$router.push({ name: "certificado_alumno" });
        },


    },
}
</script>

<style lang="scss" scoped>
    .etiquetas .btn{
        padding: 0.386rem 0.5rem;
        margin-left: 0.3rem;
    }
</style>

